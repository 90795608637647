import { TaskState } from './types';

const initialState: TaskState = {
    activity: {
        attributes: null,
        design: null,
        header: null,
        isFailedCompleted: false,
        actions: null,
    },
    georgeTask: null,
    activeComponents: [],
    componentsLoaded: false,
    hashId: null,
    awaitedResources: new Set<string>(),
};

/**
 * Exposed method for creating initial state of the task reducer.
 */
export const createInitialState = (): TaskState => {
    return { ...initialState };
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const taskReducers = (state = initialState, action): TaskState => {
    let newState;
    switch (action.type) {
        case 'STORE_HASH_ID':
            newState = { ...state, hashId: action.data };
            return newState;
        case 'CASE_CREATED':
            newState = {
                ...state,
                georgeTask: action.georgeTask,
                hashId: action.georgeTask.hashId,
            };
            return newState;
        case 'ACTIVITY_DESIGN_LOADED': {
            //hashId is set because of possibility of 'start new case' flow in case of 'back' button click into rejected case
            const updatedTask = {
                ...state.georgeTask,
                taskStatus: action.activity.taskStatus,
                hashId: action.activity.hashId,
            };
            newState = { ...state, georgeTask: updatedTask, activity: action.activity };
            return newState;
        }
        case 'COMPLETE_ACTIVITY_FAILED':
            newState = { ...state, activity: { ...state.activity, isFailedCompleted: true } };
            return newState;
        case 'COMPONENT_MOUNTED':
            newState = {
                ...state,
                activeComponents: [
                    ...state.activeComponents.filter((item) => item.name !== action.component.name),
                    action.component,
                ],
            };
            return newState;
        case 'COMPONENT_UNMOUNTED': {
            const componentIndex = state.activeComponents
                .map(function (e) {
                    return e.name;
                })
                .indexOf(action.component.name);
            if (componentIndex < 0) {
                return state;
            }
            newState = {
                ...state,
                activeComponents: [
                    ...state.activeComponents.slice(0, componentIndex),
                    ...state.activeComponents.slice(componentIndex + 1),
                ],
            };
            return newState;
        }
        case 'CLOSE_ACTIVITY': {
            const newGeorgeTask = { ...state.georgeTask, taskStatus: action.taskStatus };
            newState = {
                ...state,
                georgeTask: newGeorgeTask,
                activity: { attributes: null, design: null, header: null },
            };
            return newState;
        }
        case 'TASK_CANCELLED':
        case 'TASK_SAVED':
            newState = { ...initialState, componentsLoaded: state.componentsLoaded };
            return newState;
        case 'EDITORS_LOADED':
            newState = { ...state, componentsLoaded: true };
            return newState;
        case 'CHANGE_ATTRIBUTE': {
            const attrName = action.name;
            let attrValue = action.value;
            const append = action.append;

            // copy new state, with deepcopy of the theCase object
            newState = { ...state };

            // find the index of the attribute with will be changed
            let index = -1;
            const attributes = [...state.activity.attributes];
            for (let i = 0; i < attributes.length; i++) {
                if (attributes[i].name === attrName) {
                    index = i;
                    break;
                }
            }

            // what if the attribute cannot be found
            if (index < 0) {
                return newState;
            }

            if (append && attributes[index].type === 'LIST') {
                const attrListValue = attributes[index].value
                    ? Object.assign([], attributes[index].value)
                    : [];
                for (let i = 0; i < attrValue.length; i++) {
                    attrListValue.push(attrValue[i]);
                }
                attrValue = attrListValue;
            }

            // very fast copy of the attributes with the modification of the value and modifiedAt in the changed attribute
            const attributeCopy = [...attributes];
            const newAttributes = Object.assign(attributeCopy, {
                [index]: Object.assign({}, attributeCopy[index], {
                    value: attrValue,
                    modifiedAt: new Date().getTime(),
                }),
            });

            // return {...state, activity: {...state.activity, attributes: newAttributes}};
            newState.activity.attributes = newAttributes;
            return newState;
        }

        case 'DOCUMENT_DELETED': {
            const filteredDocuments = state.activity.attributes.filter(
                (att) => att.name === action.attrName,
            );
            if (!filteredDocuments || filteredDocuments.length === 0) {
                return state;
            }

            const documents = { ...filteredDocuments[0] };
            let documentIndex = -1;

            if (!documents) {
                console.error('documents atttribute ' + action.attrName + ' was not found');
            }

            for (let i = 0; i < documents.value.length; i++) {
                const docToCheck = documents.value[i];

                if (docToCheck.documentId === action.documentId) {
                    documentIndex = i;
                }
            }

            documents.value.splice(documentIndex, 1);
            const documentsValueCopy = [...documents.value]; // Must create new array to trigger REACT state update.
            documents.value = documentsValueCopy;

            documents.value.modifiedAt = new Date().getTime();

            const newAtts = [];
            newAtts.push(documents);

            for (let i = 0; i < state.activity.attributes.length; i++) {
                if (state.activity.attributes[i].name === documents.name) {
                    continue;
                }

                newAtts.push({ ...state.activity.attributes[i] });
            }

            const newActivity = Object.assign({}, { ...state.activity }, { attributes: newAtts });
            newState = Object.assign({}, { ...state }, { activity: newActivity });

            return newState;
        }

        case 'WAIT_FOR_RESOURCE': {
            const awaitedResources = state.awaitedResources;
            awaitedResources.add(action.fieldName);
            return { ...state, awaitedResources: new Set(awaitedResources) };
        }
        case 'RESOURCE_READY': {
            const awaitedResources = state.awaitedResources;
            awaitedResources.delete(action.fieldName);
            return { ...state, awaitedResources: new Set(awaitedResources) };
        }
        default:
            return state;
    }
};

export default taskReducers;
