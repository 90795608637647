import { DocumentsField } from '@csas-smart/gti-ui-comps';
import React, { useState } from 'react';
import {
    deleteDocument,
    loadDocument,
    loadDocuments,
    loadDocumentsByDocumentIds,
    updateFile,
    uploadFiles,
} from '../actions/documentActions';
import { loadCodebook, showErrorMessage } from '../actions/taskActions';
import { isMobileApp } from '../core/utils/salesChannelUtils';
import { AppDispatch } from '../core/store';
import { useDispatch } from 'react-redux';
import SessionWrapper from '../sessions/sessionWrapper';
import { DocumentPdfViewerModal } from '@csas-smart/gti-sigma-ui';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { useAppSelector } from '../core/hooks/hooks';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import { Alert, Modal, ModalBody } from '@george-labs.com/design-system';

const DocumentsFieldContainer = (props) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const sessionWrapper = new SessionWrapper();
    const dispatch = useDispatch<AppDispatch>();
    const hashId = useAppSelector(() => resolveHashIdFromState());

    const loadCodebookHandler = (codebook, attributes, lang) => {
        const res = loadCodebook(codebook, attributes, lang ? lang : sessionWrapper.getLanguage());
        console.log(res);
        return res;
    };

    const onDownload = (fieldName, theFile) => {
        return dispatch(loadDocument(fieldName, theFile)).then((fileContent) => {
            documentResolved(fileContent, theFile);
        }, documentRejected);
    };

    const documentRejected = (rejectReason) => {
        console.log('Loading of document failed:', rejectReason);
    };

    const documentResolved = (fileContent, theFile) => {
        //webView - way
        if (isMobileApp()) {
            const reader = new window.FileReader();
            fileContent.blob.then((blob) => {
                reader.readAsBinaryString(blob);
                reader.onloadend = () => {
                    if (!window.webkit) {
                        console.error('Window does not conatins webkit');
                        return;
                    }

                    if (!window.webkit.messageHandlers) {
                        console.error('Window does not conatins webkit.messageHandlers');
                        return;
                    }

                    if (!window.webkit.messageHandlers.readBlob) {
                        console.error('Window does not conatins webkit.messageHandlers.readBlob');
                        return;
                    }

                    window.webkit.messageHandlers.readBlob.postMessage(reader.result);
                };
            });
        } else {
            //classic way
            //document is <DOM> document. Not document object used by this "component"
            const link = document.createElement('a');

            link.setAttribute(
                'download',
                fileContent.filename ? fileContent.filename : theFile.fileName,
            );
            fileContent.blob.then((blob) => {
                link.href = window.URL.createObjectURL(new Blob([blob]));
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
        }
    };

    const displayFile = (documentId: string) => {
        setLoading(true);
        setIsOpen(true);
        return RestCaller.httpGetWithBinary(
            Resource.getDocument(props.field.name, hashId, { documentId: documentId }),
        )
            .then((response) => {
                if (response.headers.get('Content-Type').startsWith('application/pdf')) {
                    return Promise.resolve(response.blob());
                } else {
                    console.log('Cannot preview non-pdf file');
                    setIsOpen(false);
                    setErrorModalOpen(true);
                    return null;
                }
            })
            .then((newFile) => {
                setFile(newFile);
            })
            .catch((err) => {
                console.log('The loading of the file failed due to: ', err);
                setError({ stack: props.t('common:field.documents-field.documentLoadFailed') });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <DocumentsField
                loadDocuments={loadDocuments}
                loadDocumentsByDocumentIds={loadDocumentsByDocumentIds}
                loadCodebook={loadCodebookHandler}
                uploadFiles={uploadFiles}
                deleteFile={deleteDocument}
                downloadFile={onDownload}
                displayFile={displayFile}
                updateFile={updateFile}
                showErrorMessage={(message) => dispatch(showErrorMessage(message))}
                {...props}
            />

            <DocumentPdfViewerModal
                documentName={undefined}
                documentContent={file}
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
                isLoading={isLoading}
                error={error}
            />

            <Modal
                isOpen={errorModalOpen}
                onClose={() => {
                    setErrorModalOpen(false);
                }}
            >
                <ModalBody>
                    <Alert variant={Alert.VARIANT.ERROR}>
                        {props.t('common:field.documents-field.unsupportedDocumentPreview')}
                    </Alert>
                </ModalBody>
            </Modal>
        </>
    );
};

export default DocumentsFieldContainer;
